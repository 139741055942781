:root {
  // common variables::after
  --nj-vh: calc(var(--vh, 1vh) * 100);
  --nj-speed--xxs: 25ms;
  --nj-speed--xs: 50ms;
  --nj-speed--x: 125ms;
  --nj-speed--xm: 180ms;
  --nj-speed--xl: 225ms;
  --nj-speed--xx: 300ms;
  --nj-speed--xxl: 450ms;
  --nj-speed--xxx: 600ms;
  --nj-speed--4x: 800ms;
  --nj-speed--5x: 1s;
  --nj-bezier-ease: cubic-bezier(0.28, 0.82, 0.45, 0.96);
  --nj-bezier-ease-in: cubic-bezier(0.41, 0.63, 0.5, 0.86);
  --nj-bezier-ease-out: cubic-bezier(0.19, 0.6, 0.19, 0.6);
  --nj-bezier-ease-in-out: cubic-bezier(0.35, 0.11, 0.32, 0.66);

  --nj-main-transition-transform-animation: transform var(--nj-speed--xx)
    var(--nj-bezier-ease-in-out);

  --nj-opacity-primary: 0.38;
  --nj-opacity-secondary: 0.7;

  // app variables
  --nj-width--converse-ai-feedback: 360px;
  --nj-avatar-banner-height: 70px;

  --nj-vh-reduced: calc(var(--nj-vh) - var(--nj-avatar-banner-height));

  // navigation panel
  --nj-left-navigation-width: 360px;
  --nj-left-navigation-width-mobile: 320px;
  --nj-right-navigation-bar-width: 68px;

  // Ninja Neutral Palette
  --nj-neutral-000: #ffffff;
  --nj-neutral-000--rgb: 255, 255, 255;

  --nj-neutral-50: #f6f7f7;
  --nj-neutral-50--rgb: 246, 247, 247;

  --nj-neutral-100: #e8eaea;
  --nj-neutral-100--rgb: 232, 234, 234;

  --nj-neutral-150: #bbbfc0;
  --nj-neutral-150--rgb: 187, 191, 192;

  --nj-neutral-200: #8e9496;
  --nj-neutral-200--rgb: 142, 148, 150;

  --nj-neutral-300: #61696c;
  --nj-neutral-300--rgb: 97, 105, 108;

  --nj-neutral-500: #4a5357;
  --nj-neutral-500--rgb: 74, 83, 87;

  --nj-neutral-600: #343e42;
  --nj-neutral-600--rgb: 52, 62, 66;

  --nj-neutral-700: #1d282d;
  --nj-neutral-700--rgb: 29, 40, 45;

  --nj-neutral-800: #161e22;
  --nj-neutral-800--rgb: 22, 30, 34;

  --nj-neutral-900: #11181b;
  --nj-neutral-900--rgb: 17, 24, 27;

  // Ninja Disco Blue
  --nj-disco-blue-50: #e7f9ff;
  --nj-disco-blue-50--rgb: 231, 249, 255;

  --nj-disco-blue-100: #c2f1ff;
  --nj-disco-blue-100--rgb: 194, 241, 255;

  --nj-disco-blue-200: #9ee8ff;
  --nj-disco-blue-200--rgb: 158, 232, 255;

  --nj-disco-blue-300: #79dfff;
  --nj-disco-blue-300--rgb: 121, 223, 255;

  --nj-disco-blue-400: #49d4ff;
  --nj-disco-blue-400--rgb: 73, 212, 255;

  --nj-disco-blue-500: #0cc5ff;
  --nj-disco-blue-500--rgb: 12, 197, 255;

  --nj-disco-blue-600: #0aa7d9;
  --nj-disco-blue-600--rgb: 10, 167, 217;

  --nj-disco-blue-700: #0880a6;
  --nj-disco-blue-700--rgb: 8, 128, 166;

  --nj-disco-blue-800: #055973;
  --nj-disco-blue-800--rgb: 5, 89, 115;

  --nj-disco-blue-900: #033140;
  --nj-disco-blue-900--rgb: 3, 49, 64;

  // Ninja Jean Blue
  --nj-jean-blue-50: #e7f1ff;
  --nj-jean-blue-50--rgb: 231, 241, 255;

  --nj-jean-blue-100: #cee3ff;
  --nj-jean-blue-100--rgb: 206, 227, 255;

  --nj-jean-blue-200: #9dc8ff;
  --nj-jean-blue-200--rgb: 157, 200, 255;

  --nj-jean-blue-300: #6cacff;
  --nj-jean-blue-300--rgb: 108, 172, 255;

  --nj-jean-blue-400: #3b90ff;
  --nj-jean-blue-400--rgb: 59, 144, 255;

  --nj-jean-blue-500: #0a74ff;
  --nj-jean-blue-500--rgb: 10, 116, 255;

  --nj-jean-blue-600: #085dcc;

  --nj-jean-blue-650: #3844a8;
  --nj-jean-blue-650--rgb: 56, 68, 168;

  --nj-jean-blue-700: #064699;
  --nj-jean-blue-700--rgb: 6, 70, 153;

  --nj-jean-blue-800: #042f66;
  --nj-jean-blue-800--rgb: 4, 47, 102;

  --nj-jean-blue-900: #021733;
  --nj-jean-blue-900--rgb: 2, 23, 51;

  // Ninja Green
  --nj-green-50: #e7faf4;
  --nj-green-50--rgb: 231, 250, 244;

  --nj-green-100: #c4f1e4;
  --nj-green-100--rgb: 196, 241, 224;

  --nj-green-200: #94e6ce;
  --nj-green-200--rgb: 148, 230, 206;

  --nj-green-300: #65dbb8;
  --nj-green-300--rgb: 101, 219, 184;

  --nj-green-400: #36d0a2;
  --nj-green-400--rgb: 54, 208 162;

  --nj-green-500: #12c892;
  --nj-green-500--rgb: 18, 200, 146;

  --nj-green-600: #0ea075;
  --nj-green-600--rgb: 14, 160, 117;

  --nj-green-700: #0c825f;
  --nj-green-700--rgb: 12, 130, 95;

  --nj-green-800: #096449;
  --nj-green-800--rgb: 9, 100, 73;

  --nj-green-900: #064633;

  // Ninja Orange
  --nj-orange-50: #f7efeb;
  --nj-orange-50--rgb: 247, 239, 235;

  --nj-orange-100: #fce2d7;
  --nj-orange-100--rgb: 252, 226, 215;

  --nj-orange-200: #f9cab6;
  --nj-orange-200--rgb: 249, 202, 182;

  --nj-orange-300: #f7b296;
  --nj-orange-300--rgb: 247, 178, 150;

  --nj-orange-400: #f49b75;
  --nj-orange-400--rgb: 244, 155, 117;

  --nj-orange-500: #f2895d;
  --nj-orange-500--rgb: 242, 137, 93;

  --nj-orange-600: #ce744f;
  --nj-orange-600--rgb: 206, 116, 79;

  --nj-orange-700: #a96041;
  --nj-orange-700--rgb: 169, 96, 65;

  --nj-orange-800: #79452f;
  --nj-orange-800--rgb: 121, 69, 47;

  --nj-orange-900: #49291c;

  --nj-orange-950: #472819;
  --nj-orange-950--rgb: 71, 40, 25;

  // Other colors
  --nj-purple-100: #98bbfe;
  --nj-purple-100--rgb: 152, 187, 254;

  --nj-neutral-blk: #060809;
  --nj-neutral-blk--rgb: 6, 8, 9;

  --nj-orange-400: #ffac5f;
  --nj-orange-400--rgb: 255, 172, 95;

  --nj-orange-600: #d99251;
  --nj-orange-600--rgb: 217, 146, 81;

  --nj-green-400: #7bcda1;
  --nj-green-400--rgb: 123, 205, 161;

  --nj-green-500: #02d98b;
  --nj-green-500--rgb: 2, 217, 139;

  --nj-green-600: #0ea075; // old: #65DBB8;
  --nj-green-600--rgb: 14, 160, 117; // 101, 219, 184;

  --nj-random-green: rgba(48, 150, 70, 1);
  --nj-random-green--rgb: 48, 150, 70;

  --nj-random-blue: rgba(70, 85, 218, 1);
  --nj-random-blue--rgb: 70, 85, 218;

  --nj-random-purple: rgba(188, 70, 218, 1);
  --nj-random-purple--rgb: 188, 70, 218;

  --nj-random-pink: rgba(209, 71, 129, 1);
  --nj-random-pink--rgb: 209, 71, 129;

  --nj-blue-700: #055973;
  --nj-blue-700--rgb: 5, 89, 115;

  --nj-blue-50: #e7f9ff;
  --nj-blue-50--rgb: 231, 249, 255;

  --nj-red-50: #f8e1e0;
  --nj-red-50--rgb: 248, 225, 224;

  --nj-red-300: #de6b64;
  --nj-red-300--rgb: 222, 107, 100;

  --nj-red-400: #d74e45;
  --nj-red-400--rgb: 215, 78, 69;

  --nj-red-500: #d33a30;
  --nj-red-500--rgb: 211, 58, 48;

  --nj-red-600: #b33129;
  --nj-red-600--rgb: 179, 49, 41;

  --nj-red-900: #3f110e;
  --nj-red-900--rgb: 63, 17, 14;

  --nj-red-blk: #ff2d34;
  --nj-red-blk--rgb: 255, 45, 52;

  --nj-midnight-blue-900: #232b71;
  --nj-midnight-blue-900--rgb: 35, 43, 113;

  --nj-sky-blue-50: #b2c1f9;
  --nj-sky-blue-50--rgb: 178, 193, 249;

  --nj-olive-green-700: #433800;
  --nj-olive-green-700--rgb: 67, 56, 0;

  --nj-sunlit-yellow-50: #faedb7;

  --nj-yellow-100: #ffef99;

  --nj-yellow-700: #594b00;

  --nj-deep-teal-700: #1d4243;
  --nj-deep-teal-700--rgb: 29, 66, 67;

  --nj-deep-teal-800: #1e333e;
  --nj-deep-teal-800--rgb: 30, 51, 62;

  --nj-seagull-50: #e9f2f7;
  --nj-seagull-50--rgb: 233, 242, 247;

  --nj-seagull-100: #d2e6f1;
  --nj-seagull-100--rgb: 210, 230, 241;

  --nj-seagull-200: #9dc1c3;
  --nj-seagull-200--rgb: 157, 193, 195;

  --nj-seagull-300: #7cc0e1;
  --nj-seagull-300--rgb: 124, 192, 225;

  --nj-seagull-400: #9ecee7;
  --nj-seagull-400--rgb: 158, 206, 231;

  --nj-seagull-700: #2d4b59;
  --nj-seagull-700--rgb: 45, 75, 89;

  --nj-light-teal-100: #c4dadb;
  --nj-light-teal-100--rgb: 196, 218, 219;

  --nj-snuff-100: #d6d6f3;
  --nj-snuff-100--rgb: 214, 214, 243;

  --nj-snuff-700: #304598;
  --nj-snuff-700--rgb: 48, 69, 152;

  --nj-orchid-800: #501d45;
  --nj-orchid-800--rgb: 80, 29, 69;

  --nj-orchid-50: #f8eef5;
  --nj-orchid-50--rgb: 248, 238, 245;

  --nj-orchid-100: #f4dced;
  --nj-orchid-100--rgb: 244, 220, 237;

  --nj-orchid-200: #ecb6de;
  --nj-orchid-200--rgb: 236, 182, 222;

  --nj-orchid-700: #722a64;
  --nj-orchid-700--rgb: 114, 42, 100;

  --nj-burning-sand-100: #f1ded6;
  --nj-burning-sand-100--rgb: 241, 222, 214;

  --nj-burning-sand-300: #e8bda8;
  --nj-burning-sand-300--rgb: 232, 189, 168;

  --nj-burning-sand-700: #693922;
  --nj-burning-sand-700--rgb: 105, 57, 34;

  // Color variables: non-themed
  --nj-color--green: var(--nj-green-600);
  --nj-color--red: var(--nj-red-500);
  --nj-color--red-alert: rgba(var(--nj-red-50--rgb), 0.56);

  --nj-google-border: #747775;
  --nj-tags-chips-calendar-hash-bg: rgba(142, 162, 243, 0.8);
  --nj-tags-chips-research-hash-bg: #f7e390;
  --nj-avatar-background: #625852;

  --nj-error-background: rgba(var(--nj-red-400--rgb), 0.12);
  --nj-error-color: var(--nj-red-400);

  --nj-success-background: var(--nj-green-50);
  --nj-success-color: var(--nj-green-900);

  --nj-font--body: "Roboto Flex", sans-serif;
  --nj-font--heading: "Roboto Flex", sans-serif;
  --nj-font--subheading: "Roboto Flex", sans-serif;

  // Light Mode VARS:
  --nj-background: var(--nj-neutral-000);
  --nj-background--rgb: var(--nj-neutral-000--rgb);
  --nj-border: var(--nj-neutral-150);
  --nj-panel: var(--nj-neutral-100);
  --nj-panel--rgb: var(--nj-neutral-100--rgb);
  --nj-tooltip: rgba(var(--nj-neutral-700--rgb), 0.7);
  --nj-tooltip-light: rgba(var(--nj-neutral-700--rgb), 0.5);
  --nj-overlay: rgba(var(--nj-neutral-000--rgb), 0.5);
  --nj-modal-overlay: rgba(var(--nj-neutral-600--rgb), 0.2);
  --nj-blur: rgba(var(--nj-neutral-000--rgb), 0.5);
  --nj-shadow: rgba(var(--nj-neutral-200--rgb), 0.24);
  --nj-shadow-darker: rgba(var(--nj-neutral-200--rgb), 0.5);
  --nj-deep-shadow: rgba(var(--nj-neutral-900--rgb), 0.24);
  --nj-rising-shadow--top-1: 0 2px 4px 0 var(--nj-deep-shadow);
  --nj-rising-shadow--top-2: 0 4px 8px 0 var(--nj-deep-shadow);
  --nj-rising-shadow--left-1: 2px 0 4px 0 var(--nj-deep-shadow);
  --nj-rising-shadow--right-1: -2px 0 24px 0 var(--nj-deep-shadow);
  --nj-divider: var(--nj-neutral-200);
  --nj-loading: var(--nj-neutral-900);
  --nj-ninja-loader: var(--nj-neutral-300);
  --nj-drag-and-drop-overlay: rgba(var(--nj-neutral-50--rgb), 0.7);

  // slider
  --nj-slider-track: var(--nj-jean-blue-500);
  --nj-slider-rail: rgba(var(--nj-jean-blue-500--rgb), 0.12);

  // text
  --nj-foreground: var(--nj-neutral-700);
  --nj-foreground--inverse: var(--nj-neutral-900);
  --nj-foreground--inverse--rgb: var(--nj-neutral-900--rgb);
  --nj-foreground--secondary: var(--nj-neutral-300);
  --nj-foreground--tertiary: var(--nj-neutral-200);
  --nj-foreground--quaternary: var(--nj-neutral-100);
  --nj-foreground--link: var(--nj-jean-blue-500);

  // buttons
  --nj-primary-button--background: var(--nj-jean-blue-500);
  --nj-primary-button--foreground: var(--nj-neutral-000);
  --nj-secondary-button--background: var(--nj-neutral-000);
  --nj-secondary-button--foreground: var(--nj-foreground);
  --nj-outlined-button--border: var(--nj-jean-blue-500);
  --nj-outlined-button--foreground: var(--nj-jean-blue-500);
  --nj-regular-button--border: var(--nj-neutral-150);
  --nj-regular-button--foreground: var(--nj-neutral-700);
  --nj-text-button--foreground: var(--nj-jean-blue-500);
  --nj-button-overlay--background: rgba(var(--nj-neutral-100--rgb), 0.06);
  --nj-button-overlay--background-sm: rgba(var(--nj-neutral-100--rgb), 0.5);
  --nj-button-overlay--background-md: rgba(var(--nj-neutral-100--rgb), 0.5);
  --nj-merged-button--background: rgba(var(--nj-jean-blue-500--rgb), 0.24);
  --nj-merged-button--foreground: var(--nj-jean-blue-600);
  --nj-feedback-selected-button--background: var(--nj-jean-blue-600);
  --nj-button-fab--background: var(--nj-neutral-100);
  --nj-button-fab--foreground: var(--nj-neutral-300);
  --nj-button-fab-inline--background: var(--nj-neutral-700);
  --nj-button-fab-inline--foreground: var(--nj-neutral-000);
  --nj-button--pro: var(--nj-green-600);
  --nj-button-research-card-background: var(--nj-neutral-000);
  --nj-new-chat-button--background: var(--nj-neutral-700);
  --nj-mode-button-background: var(--nj-jean-blue-100);
  --nj-progress-button-background: var(--nj-jean-blue-50);

  // design system buttons > common button > primary
  --nj-common-button--primary-enabled--background: var(--nj-jean-blue-500);
  --nj-common-button--primary-enabled--foreground: var(--nj-neutral-000);
  --nj-common-button--primary-hovered--shadow: 0px 2px 4px 0px
    rgba(var(--nj-jean-blue-700--rgb), 0.48);
  --nj-common-button--primary-focused--border: var(--nj-jean-blue-600);
  --nj-common-button--primary-focused--shadow: 0px 1px 4px 0px
    rgba(var(--nj-jean-blue-800--rgb), 0.48);
  --nj-common-button--primary-pressed--background: var(--nj-jean-blue-600);

  // design system buttons > common button > secondary
  --nj-common-button--secondary-enabled--foreground: var(--nj-neutral-700);
  --nj-common-button--secondary-enabled--border: var(--nj-neutral-700);
  --nj-common-button--secondary-hovered--background: rgba(
    var(--nj-jean-blue-500--rgb),
    0.12
  );
  --nj-common-button--secondary-hovered--shadow: 0px 2px 4px 0px
    rgba(var(--nj-neutral-900--rgb), 0.24);
  --nj-common-button--secondary-focused--border: var(--nj-neutral-150);
  --nj-common-button--secondary-focused--background: rgba(
    var(--nj-neutral-600--rgb),
    0.12
  );
  --nj-common-button--secondary-pressed--foreground: var(--nj-neutral-500);
  --nj-common-button--secondary-pressed--background: rgba(
    var(--nj-jean-blue-500--rgb),
    0.2
  );

  // rich content
  --nj-card--background: var(--nj-neutral-100);
  --nj-card--link: var(--nj-jean-blue-600);
  --nj-snackbar: rgba(var(--nj-neutral-100--rgb), 0.12);

  // tabs
  --nj-tab--background-selected: rgba(var(--nj-jean-blue-500--rgb), 0.12);
  --nj-tab--foreground-selected: var(--nj-jean-blue-600);
  --nj-tab-list--header-button--background: rgba(
    var(--nj-neutral-600--rgb),
    0.06
  );

  // tasks
  --nj-task--background-hover: rgba(var(--nj-neutral-600--rgb), 0.06);

  // pillars
  --nj-pillar-tile--background: var(--nj-neutral-000);
  --nj-pillar-icon--background: transparent;
  --nj-pillar-dot--background: var(--nj-neutral-100);
  --nj-pillar-dot--background--disabled: var(--nj-jean-blue-600);

  // main navigation
  --nj-nav--background: rgba(var(--nj-neutral-50--rgb), 1);
  --nj-nav--selected-background: rgba(var(--nj-jean-blue-500--rgb), 0.5);
  --nj-nav--selected-border: var(--nj-jean-blue-500);
  --nj-nav--selected-foreground: var(--nj-jean-blue-600);
  --nj-nav--hovered-background: rgba(var(--nj-neutral-600--rgb), 0.06);
  --nj-nav--focused-background: rgba(var(--nj-neutral-600--rgb), 0.12);
  --nj-nav--pressed-background: rgba(var(--nj-neutral-50--rgb), 0.7);

  // pillars
  --nj-pillar-tile--background: var(--nj-neutral-000);
  --nj-pillar-icon--background: transparent;
  --nj-pillar-dot--background: var(--nj-neutral-100);
  --nj-pillar-dot--background-selected: var(--nj-neutral-700);
  --nj-pillars--arrow: var(--nj-neutral--100);
  --nj-pillars--arrow-disabled: var(--nj-neutral-700);

  // ai status
  --nj-status--blocked: var(--nj-red-300);
  --nj-status--in-progress: var(--nj-jean-blue-500);
  --nj-status--done: var(--nj-neutral-300);
  --nj-status--completed: var(--nj-green-600);
  --nj-status--counter: var(--nj-red-500);
  --nj-status--alert: var(--nj-red-300);
  --nj-status--accent: var(--nj-jean-blue-500);
  --nj-status--accent--rgb: var(--nj-jean-blue-500--rgb);
  --nj-status--is-required: var(--nj-red-600);
  --nj-status--success: var(--nj-green-500);
  --nj-status--attention: var(--nj-red-blk);
  --nj-status--warning: var(--nj-orange-600);
  --nj-status--audio: var(--nj-jean-blue-600);
  --nj-status--blocked-background: var(--nj-red-50);

  // chat bubble
  --nj-bubble--icon: var(--nj-neutral-300);
  --nj-bubble--agent: rgba(var(--nj-neutral-100--rgb), 0.7);
  --nj-bubble--user: rgba(var(--nj-jean-blue-200--rgb), 0.7);
  --nj-avatar-bubble--agent: rgba(var(--nj-neutral-150--rgb), 0.95);
  --nj-avatar-bubble--user: rgba(var(--nj-jean-blue-200--rgb), 0.95);

  // action tray
  --nj-tray--background: rgba(var(--nj-neutral-50--rgb), 0.5);
  --nj-tray-fa--background: var(--nj-jean-blue-500);
  --nj-tray-fa--selected-background: rgba(var(--nj-jean-blue-500--rgb), 0.24);
  --nj-tray-fa--selected-foreground: var(--nj-jean-blue-600);

  // field
  --nj-input--background: var(--nj-neutral-50);
  --nj-input--background-lighter: rgba(var(--nj-neutral-800--rgb), 0.7);
  --nj-input--placeholder: var(--nj-neutral-150);
  --nj-input--disabled: var(--nj-neutral-150);
  --nj-input--focus: var(--nj-jean-blue-500);
  --nj-chatform--background: var(--nj-neutral-50);
  --nj-chatform--comments: var(--nj-neutral-600);
  --nj-select--background: var(--nj-neutral-600);
  --nj-select--foreground: var(--nj-neutral-100);
  --nj-progress--background-empty: rgba(var(--nj-jean-blue-700--rgb), 0.12);
  --nj-progress--background-filled: var(--nj-jean-blue-500);
  --nj-switch--background-off: rgba(var(--nj-neutral-200--rgb), 0.24);
  --nj-switch--background-on: rgba(var(--nj-jean-blue-500--rgb), 0.24);
  --nj-switch--foreground: var(--nj-neutral-000);
  --nj-checkbox--background: rgba(var(--nj-neutral-100--rgb), 0.5);
  --nj-checkbox--foreground--selected: var(--nj-status--accent);

  // appbar
  --nj-appbar--button--background: var(--nj-neutral-600);
  --nj-appbar--button--foreground: var(--nj-neutral-100);
  --nj-appbar--button-icon--background: var(--nj-neutral-600);
  --nj-appbar--button-icon--hover: var(--nj-neutral-300);
  --nj-appbar--button-icon--active: var(--nj-neutral-300);
  --nj-appbar--button-icon--focus: var(nj-neutral-600);
  --nj-appbar--mobile--height: 64px;
  --nj-appbar--reddot-border: var(--nj-neutral-000);
  --nj-appbar--thread-toggle-icon: var(--nj-neutral-300);

  // thread
  --nj-thread--toggle-button--background: var(--nj-neutral-100);
  --nj-appbar--thread-button--foreground: var(--nj-neutral-700);
  --nj-thread--toggle-icon: var(--nj-neutral-700);
  --nj-thread--gradient-bottom--background: linear-gradient(
    var(--nj-neutral-100),
    var(--nj-neutral-100) 20%,
    var(--nj-neutral-100)
  );
  --nj-thread--gradient-top--background: linear-gradient(
    var(--nj-neutral-000) 50%,
    rgba(var(--nj-neutral-000--rgb), 0) 100%
  );
  --nj-thread--gradient-top--background-2: linear-gradient(
    var(--nj-neutral-000) 90%,
    rgba(var(--nj-neutral-000--rgb), 0) 100%
  );

  // nj-app-nav
  --nj-appbar-header--button--foreground: var(--nj-neutral-300);
  --nj-appbar-header--search--background: var(--nj-neutral-000);
  --nj-appbar-footer--background: var(--nj-neutral-150);

  // mic
  --nj-mic--border: rgba(var(--nj-neutral-200--rgb), 0.1);
  --nj-meta--background: rgba(var(--nj-neutral-100--rgb), 1);
  --nj-meta--background-selected: rgba(var(--nj-neutral-900--rgb), 1);
  --nj-meta--foreground-selected: var(--nj-neutral-150);

  // avatar-mode
  --nj-avatar--background: #000;
  --nj-avatar--control-button-backgroud: var(--nj-neutral-100);
  --nj-avatar--control-button-pressed-background: rgba(
    var(--nj-jean-blue-500--rgb),
    0.12
  );
  --nj-avatar--control-button-highlighted-background: rgba(
    var(--nj-red-500--rgb),
    0.12
  );
  --nj-avatar-menu--background: rgba(var(--nj-neutral-50--rgb), 0.7);
  --nj-avatar--control-selected-color: var(--nj-jean-blue-600);
  --nj-avatar--control-highlighted-color: var(--nj-red-500);
  --nj-avatar--background-gradient: linear-gradient(
    270deg,
    var(--nj-neutral-000) 0%,
    var(--nj-neutral-000) 59%,
    rgba(var(--nj-neutral-000--rgb), 0.24) 85%,
    rgba(var(--nj-neutral-000--rgb), 0) 100%
  );

  // header
  --nj-header--control-button-background: var(--nj-neutral-000);
  --nj-header--control-actions-backgroud: var(--nj-neutral-100);
  --nj-header--control-shadow: rgba(var(--nj-neutral-900--rgb), 0.24);

  // cards
  --nj-card-entry--background: var(--nj-neutral-50);
  --nj-card-entry--border: var(--nj-neutral-100);
  --nj-card-rich-content--background: rgba(var(--nj-neutral-100--rgb, 0.5));

  // media
  --nj-media-overlay--background: rgba(var(--nj-neutral-900--rgb), 0.7);
  --nj-media-overlay--foreground: var(--nj-neutral-000);

  // tags & chips
  --nj-tags-chips--calendar-bg: var(--nj-snuff-100);
  --nj-tags-chips--calendar-txt: var(--nj-neutral-700);
  --nj-tags-chips--research-bg: var(--nj-seagull-100);
  --nj-tags-chips--research-txt: var(--nj-neutral-700);
  --nj-tags-chips--research-title: var(--nj-seagull-700);
  --nj-tags-chips--advisor-bg: var(--nj-orchid-100);
  --nj-tags-chips--advisor-txt: var(--nj-neutral-700);
  --nj-tags-chips--advisor-title: var(--nj-orchid-700);
  --nj-tags-chips--coder-bg: var(--nj-burning-sand-100);
  --nj-tags-chips--coder-txt: var(--nj-neutral-700);
  --nj-tags-chips--persona-bg: var(--nj-neutral-100);
  --nj-tags-chips--persona-txt: var(--nj-neutral-300);
  --nj-tags-chips--image-bg: var(--nj-yellow-100);
  --nj-tags-chips--image-txt: var(--nj-neutral-700);

  --nj-avatar-banner--background: var(--nj-jean-blue-100);
  --nj-avatar-banner--border: var(--nj-jean-blue-200);
  --nj-avatar-banner--color: var(--nj-jean-blue-700);

  // gradients
  --nj-external-researcher-linear-gradient: linear-gradient(
    180deg,
    var(--nj-seagull-50) 0%,
    rgba(var(--nj-seagull-50--rgb), 0) 70%
  );
}

.nj-theme.dark-mode {
  --nj-background: var(--nj-neutral-900);
  --nj-background--rgb: var(--nj-neutral-900--rgb);
  --nj-border: var(--nj-neutral-600);
  --nj-panel: var(--nj-neutral-700);
  --nj-panel--rgb: var(--nj-neutral-700--rgb);
  --nj-tooltip: rgba(var(--nj-neutral-100--rgb), 0.5);
  --nj-tooltip-light: rgba(var(--nj-neutral-100--rgb), 0.5);
  --nj-overlay: rgba(var(--nj-neutral-900--rgb), 0.7);
  --nj-modal-overlay: rgba(var(--nj-neutral-600--rgb), 0.5);
  --nj-blur: rgba(var(--nj-neutral-900--rgb), 0.7);
  --nj-shadow: rgba(var(--nj-neutral-900--rgb), 0.24);

  --nj-shadow-darker: rgba(var(--nj-neutral-900--rgb), 0.5);
  --nj-divider: var(--nj-neutral-200);
  --nj-loading: var(--nj-neutral-900);
  --nj-ninja-loader: var(--nj-neutral-100);
  --nj-drag-and-drop-overlay: rgba(var(--nj-neutral-600--rgb), 0.7);

  // slider
  --nj-slider-track: var(--nj-disco-blue-500);
  --nj-slider-rail: rgba(var(--nj-disco-blue-500--rgb), 0.12);

  // text
  --nj-foreground: var(--nj-neutral-150);
  --nj-foreground--inverse: var(--nj-neutral-000);
  --nj-foreground--inverse--rgb: var(--nj-neutral-000--rgb);
  --nj-foreground--secondary: var(--nj-neutral-200);
  --nj-foreground--tertiary: var(--nj-neutral-300);
  --nj-foreground--quaternary: var(--nj-neutral-100);
  --nj-foreground--link: var(--nj-disco-blue-500);

  // buttons
  --nj-primary-button--background: var(--nj-disco-blue-500);
  --nj-primary-button--foreground: var(--nj-neutral-700);
  --nj-secondary-button--background: var(--nj-neutral-000);
  --nj-secondary-button--foreground: var(--nj-foreground);
  --nj-outlined-button--border: var(--nj-disco-blue-500);
  --nj-outlined-button--foreground: var(--nj-disco-blue-500);
  --nj-text-button--foreground: var(--nj-disco-blue-500);
  --nj-button-overlay--background-sm: rgba(var(--nj-neutral-000--rgb), 0.06);
  --nj-button-overlay--background: rgba(var(--nj-neutral-000--rgb), 0.24);
  --nj-button-overlay--background-md: rgba(var(--nj-neutral-900--rgb), 0.5);
  --nj-merged-button--background: rgba(var(--nj-disco-blue-500--rgb), 0.25);
  --nj-merged-button--foreground: var(--nj-disco-blue-500);
  --nj-feedback-selected-button--background: var(--nj-disco-blue-500);
  --nj-button-fab--background: var(--nj-neutral-100);
  --nj-button-fab--foreground: var(--nj-neutral-300);
  --nj-button-fab-inline--background: var(--nj-neutral-000);
  --nj-button-fab-inline--foreground: var(--nj-neutral-700);
  --nj-button--pro: var(--nj-green-600);
  --nj-button-research-card-background: rgba(
    var(--nj-disco-blue-500--rgb),
    0.12
  );
  --nj-new-chat-button--background: var(--nj-neutral-150);
  --nj-mode-button-background: var(--nj-disco-blue-100);
  --nj-progress-button-background: var(--nj-disco-blue-900);

  // design system buttons > common button > primary
  --nj-common-button--primary-enabled--background: var(--nj-disco-blue-500);
  --nj-common-button--primary-enabled--foreground: var(--nj-neutral-700);
  --nj-common-button--primary-hovered--shadow: 0px 2px 4px 0px
    rgba(var(--nj-disco-blue-300--rgb), 0.48);
  --nj-common-button--primary-focused--border: var(--nj-disco-blue-600);
  --nj-common-button--primary-focused--shadow: 0px 1px 4px 0px
    rgba(var(--nj-disco-blue-200--rgb), 0.48);
  --nj-common-button--primary-pressed--background: var(--nj-disco-blue-600);

  // design system buttons > common button > secondary
  --nj-common-button--secondary-enabled--foreground: var(--nj-neutral-150);
  --nj-common-button--secondary-enabled--border: var(--nj-neutral-150);
  --nj-common-button--secondary-hovered--background: rgba(
    var(--nj-jean-blue-500--rgb),
    0.12
  ); //
  --nj-common-button--secondary-hovered--shadow: 0px 2px 4px 0px
    rgba(var(--nj-neutral-900--rgb), 0.24);
  --nj-common-button--secondary-focused--border: var(--nj-neutral-150);
  --nj-common-button--secondary-focused--background: rgba(
    var(--nj-neutral-600--rgb),
    0.12
  );
  --nj-common-button--secondary-pressed--foreground: var(--nj-neutral-200);
  --nj-common-button--secondary-pressed--background: rgba(
    var(--nj-jean-blue-500--rgb),
    0.2
  ); //

  // rich content
  --nj-card--background: var(--nj-neutral-700);
  --nj-card--link: var(--nj-disco-blue-600);
  --nj-snackbar: rgba(var(--nj-neutral-100--rgb), 0.12);

  // tabs
  --nj-tab--background-selected: rgba(var(--nj-disco-blue-500--rgb), 0.12);
  --nj-tab--foreground-selected: var(--nj-disco-blue-500);
  --nj-tab-list--header-button--background: rgba(
    var(--nj-neutral-600--rgb),
    0.5
  );

  // tasks
  --nj-task--background-hover: rgba(var(--nj-neutral-100--rgb), 0.06);

  // pillars
  --nj-pillar-tile--background: var(--nj-neutral-700);
  --nj-pillars--arrow: var(--nj-neutral--700);
  --nj-pillars--arrow-disabled: var(--nj-neutral-100);
  --nj-pillar-dot--background-selected: var(--nj-neutral-150);

  // main navigation
  --nj-nav--background: rgba(var(--nj-neutral-800--rgb), 1);
  --nj-nav--selected-background: rgba(var(--nj-disco-blue-500--rgb), 0.5);
  --nj-nav--selected-border: var(--nj-disco-blue-500);
  --nj-nav--selected-foreground: var(--nj-disco-blue-600);
  --nj-nav--hovered-background: rgba(var(--nj-neutral-100--rgb), 0.06);
  --nj-nav--focused-background: rgba(var(--nj-neutral-100--rgb), 0.12);
  --nj-nav--pressed-background: rgba(var(--nj-neutral-800--rgb), 0.7);

  // ai status
  --nj-status--blocked: var(--nj-red-300);
  --nj-status--in-progress: var(--nj-disco-blue-500);
  --nj-status--done: var(--nj-neutral-300);
  --nj-status--completed: var(--nj-green-600);
  --nj-status--counter: var(--nj-red-500);
  --nj-status--alert: var(--nj-red-300);
  --nj-status--accent: var(--nj-disco-blue-500);
  --nj-status--accent--rgb: var(--nj-disco-blue-500--rgb);
  --nj-status--is-required: var(--nj-red-600);
  --nj-status--success: var(--nj-green-500);
  --nj-status--attention: var(--nj-red-blk);
  --nj-status--audio: var(--nj-disco-blue-500);
  --nj-status--blocked-background: var(--nj-red-900);

  // chat bubble
  --nj-bubble--icon: var(--nj-neutral-300);
  --nj-bubble--agent: rgba(var(--nj-neutral-600--rgb), 0.5);
  --nj-bubble--user: rgba(var(--nj-disco-blue-800--rgb), 0.5);
  --nj-avatar-bubble--agent: rgba(var(--nj-neutral-600--rgb), 0.95);
  --nj-avatar-bubble--user: rgba(var(--nj-disco-blue-800--rgb), 0.95);

  // pillars
  --nj-pillar-tile--background: var(--nj-neutral-900);
  --nj-pillar-icon--background: var(--nj-neutral-700);
  --nj-pillar-dot--background: var(--nj-neutral-600);
  --nj-pillars--arrow: var(--nj-neutral--000);
  --nj-pillars--arrow-disabled: var(--nj-neutral-100);

  // action tray
  --nj-tray--background: rgba(var(--nj-neutral-800--rgb), 0.5);
  --nj-tray-fa--background: var(--nj-disco-blue-500);
  --nj-tray-fa--selected-background: rgba(var(--nj-disco-blue-500--rgb), 0.24);
  --nj-tray-fa--selected-foreground: var(--nj-disco-blue-600);

  // field
  --nj-input--background: var(--nj-neutral-800);
  --nj-input--background-lighter: rgba(var(--nj-neutral-800--rgb), 0.7);
  --nj-input--placeholder: var(--nj-neutral-300);
  --nj-input--disabled: var(--nj-neutral-300);
  --nj-input--focus: var(--nj-disco-blue-500);
  --nj-chatform--background: var(--nj-neutral-700);
  --nj-chatform--comments: var(--nj-neutral-300);
  --nj-select--background: var(--nj-neutral-600);
  --nj-select--foreground: var(--nj-neutral-100);
  --nj-progress--background-empty: var(--nj-neutral-600);
  --nj-progress--background-filled: var(--nj-disco-blue-500);
  --nj-switch--background-off: rgba(var(--nj-neutral-100--rgb), 0.24);
  --nj-switch--background-on: rgba(var(--nj-disco-blue-500--rgb), 0.24);
  --nj-switch--foreground: var(--nj-neutral-150);
  --nj-checkbox--background: rgba(var(--nj-neutral-300--rgb), 0.5);
  --nj-checkbox--foreground--selected: var(--nj-status--accent);

  // appbar
  --nj-appbar--button--background: var(--nj-neutral-600);
  --nj-appbar--button--foreground: var(--nj-neutral-100);
  --nj-appbar--button-icon--background: var(--nj-neutral-600);
  --nj-appbar--button-icon--hover: var(--nj-neutral-300);
  --nj-appbar--button-icon--active: var(--nj-neutral-300);
  --nj-appbar--button-icon--focus: var(nj-neutral-600);
  --nj-appbar--mobile--height: 64px;
  --nj-appbar--reddot-border: var(--nj-neutral-600);

  // thread
  --nj-thread--toggle-button--background: var(--nj-neutral-700);
  --nj-appbar--thread-button--foreground: var(--nj-neutral-000);
  --nj-thread--toggle-icon: var(--nj-neutral-000);
  --nj-thread--gradient-bottom--background: linear-gradient(
    var(--nj-background),
    var(--nj-background) 20%,
    var(--nj-background)
  );
  --nj-thread--gradient-top--background: linear-gradient(
    var(--nj-background) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  --nj-thread--gradient-top--background-2: linear-gradient(
    var(--nj-background) 85%,
    rgba(0, 0, 0, 0) 100%
  );

  // nj-app-nav
  --nj-appbar-header--button--foreground: var(--nj-neutral-150);
  --nj-appbar-header--search--background: var(--nj-neutral-800);
  --nj-appbar-footer--background: var(--nj-neutral-800);

  // mic
  --nj-mic--border: rgba(var(--nj-neutral-000--rgb), 0.1);
  --nj-meta--background: rgba(var(--nj-neutral-700--rgb), 1);
  --nj-meta--background-selected: rgba(var(--nj-neutral-150--rgb), 1);
  --nj-meta--foreground-selected: var(--nj-neutral-700);

  // avatar-mode
  --nj-avatar--background: #000;
  --nj-avatar--control-button-backgroud: var(--nj-neutral-700);
  --nj-avatar--control-button-pressed-background: rgba(
    var(--nj-disco-blue-500--rgb),
    0.12
  );
  --nj-avatar--control-button-highlighted-background: rgba(
    var(--nj-red-500--rgb),
    0.12
  );
  --nj-avatar-menu--background: rgba(var(--nj-neutral-800--rgb), 0.7);
  --nj-avatar--control-selected-color: var(--nj-disco-blue-500);
  --nj-avatar--control-highlighted-color: var(--nj-red-500);
  --nj-avatar--background-gradient: linear-gradient(
    270deg,
    var(--nj-neutral-900) 0%,
    var(--nj-neutral-900) 59%,
    rgba(var(--nj-neutral-900--rgb), 0.24) 85%,
    rgba(var(--nj-neutral-900--rgb), 0) 100%
  );

  // header
  --nj-header--control-button-background: var(--nj-neutral-900);
  --nj-header--control-actions-backgroud: var(--nj-neutral-900);
  --nj-header--control-shadow: rgba(var(--nj-neutral-900--rgb), 0.24);

  // cards
  --nj-card-entry--background: var(--nj-neutral-900);
  --nj-card-entry--border: var(--nj-border);
  --nj-card-rich-content--background: rgba(var(--nj-panel--rgb, 0.7));

  // media
  --nj-media-overlay--background: rgba(var(--nj-neutral-900--rgb), 0.7);
  --nj-media-overlay--foreground: var(--nj-neutral-000);

  // tags & chips
  --nj-tags-chips--calendar-bg: var(--nj-snuff-700);
  --nj-tags-chips--calendar-txt: var(--nj-neutral-150);
  --nj-tags-chips--research-bg: var(--nj-deep-teal-700);
  --nj-tags-chips--research-txt: var(--nj-neutral-150);
  --nj-tags-chips--research-title: var(--nj-seagull-400);
  --nj-tags-chips--advisor-bg: var(--nj-orchid-700);
  --nj-tags-chips--advisor-txt: var(--nj-neutral-150);
  --nj-tags-chips--coder-bg: var(--nj-burning-sand-700);
  --nj-tags-chips--coder-txt: var(--nj-neutral-150);
  --nj-tags-chips--coder-title: var(--nj-burning-sand-300);
  --nj-tags-chips--persona-bg: var(--nj-neutral-700);
  --nj-tags-chips--persona-txt: var(--nj-neutral-150);
  --nj-tags-chips--image-bg: var(--nj-yellow-700);
  --nj-tags-chips--image-txt: var(--nj-neutral-150);

  // gradients
  --nj-external-researcher-linear-gradient: linear-gradient(
    180deg,
    var(--nj-deep-teal-800) 0%,
    rgba(var(--nj-deep-teal-800--rgb), 0) 70%
  );

  // avatar banner
  --nj-avatar-banner--background: var(--nj-disco-blue-800);
  --nj-avatar-banner--border: var(--nj-disco-blue-800);
  --nj-avatar-banner--color: var(--nj-neutral-150);
}

// todo(ella): remove variables below
$nj-background--thread: var(--nj-neutral-600);
$nj-foreground--thread: var(--nj-neutral-100);

// appbar config
$nj-background--appbar: var(--nj-background);
$nj-foreground--appbar: var(--nj-neutral-000);

$nj-background--appbar-button: transparent;
$nj-background--appbar-button-hover: var(--nj-neutral-300);
$nj-foreground--appbar-button-hover: var(--nj-neutral-000);
$nj-background--appbar-button-active: var(--nj-neutral-300);
$nj-foreground--appbar-button-active: var(--nj-neutral-100);
$nj-background--appbar-button-selected: var(--nj-neutral-600);
$nj-foreground--appbar-button-selected: var(--nj-disco-blue-500);
$nj-height--app-mobilebar: 64px;

// tasks
$nj-background--task-status-working: var(--nj-jean-blue-500);
$nj-foreground--task-status-working: var(--nj-jean-blue-500);
$nj-background--task-status-blocked: var(--nj-orange-400);
$nj-foreground--task-status-blocked: var(--nj-neutral-700);
$nj-background--task-status-paused: var(--nj-green-400);
$nj-foreground--task-status-paused: var(--nj-green-400);
$nj-background--task-status-completed: var(--nj-neutral-200);
$nj-foreground--task-status-completed: var(--nj-neutral-200);
$nj-foreground--content-pane: var(--nj-neutral-100);
$nj-foreground--content-pane-heading: var(--nj-neutral-000);

// task status colors
$nj-background--task-status-working: var(--nj-jean-blue-500);
$nj-foreground--task-status-working: var(--nj-jean-blue-500);
$nj-background--task-status-completed: var(--nj-neutral-200);
$nj-foreground--task-status-completed: var(--nj-neutral-200);

// ea card
// $nj-background--ea-card: rgba($nj-accent-100, 0.12);
$nj-foreground--ea-card: var(--nj-neutral-000);
$nj-foreground--ea-card-caption: var(--nj-neutral-200);

// typography colors
$nj-foreground--content-pane: var(--nj-background);
$nj-foreground--content-pane-heading: var(--nj-background);
$nj-foreground--title: var(--nj-neutral-000);
$nj-foreground--date: var(--nj-neutral-100);
$nj-foreground--secondary: var(--nj-neutral-200);
