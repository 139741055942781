@import "src/styles/placeholders/flex-placeholders";
@import "src/styles/placeholders/typography-placeholders";
@import "src/styles/breakpoints";

.root {
  @extend %flex-center;

  background-color: var(--nj-background);
  border-radius: 12px;
  width: 100%;
  height: 100%;

  &.withText {
    background-color: var(--nj-panel);
  }
}

.errorContainer {
  @extend %caption-1;
  @extend %flex-column-center;
  justify-content: center;
  padding: 8px;
  text-align: center;
  cursor: auto;
  width: 100%;
  height: 100%;

  svg {
    margin-bottom: 8px;
    fill: var(--nj-foreground--secondary);
  }
}
