@import 'src/styles/placeholders/flex-placeholders';

.root {
  @extend %flex-row;

  padding: 10px 16px;
  border-radius: 20px;
  flex-wrap: nowrap;
  width: 208px;
  max-width: 208px;
  justify-content: flex-start;

  &.active {
    background-color: var(--nj-common-button--secondary-hovered--background);

    .title {
      color: var(--nj-merged-button--foreground);
    }
  }
}

.title {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--nj-foreground--link);
}
