html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  overflow-wrap: initial;
  background-color: var(--nj-background);
  color: var(--nj-foreground);
  max-width: 100vw;
  font-smooth: auto;
  position: relative;
  font-family: var(--nj-font--body);
  font-style: normal;
  font-stretch: normal;
  font-size: 16px;
  line-height: 1.5;
  height: var(--nj-vh);
  @include reset-browser-styles;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--nj-vh);
  overflow: hidden;
}

a,
button {
  cursor: pointer;
}

*::-webkit-scrollbar {
  display: none;
}

body > div {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.nj-loading {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--nj-vh);
  z-index: 1000;

  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
}

.nj-markdown-block-wrapper {
  display: flex;
  flex-direction: column;
  background: var(--nj-nav--pressed-background);
  padding: 0 8px 8px;
  border-radius: 16px;
}
.nj-markdown-block-wrapper .nj-markdown-copy-block-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 8px 8px;
  color: var(--nj-foreground--secondary);
  align-items: center;
}
.nj-markdown-block-wrapper .nj-markdown-copy-block-wrapper h6 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: var(--nj-foreground);
}
.nj-markdown-block-wrapper .nj-markdown-copy-block-wrapper button {
  color: var(--nj-outlined-button--foreground);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.nj-markdown-block-wrapper .divider {
  width: calc(100% + 16px);
  margin: 8px 0 8px -8px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
