@import 'src/styles/placeholders/flex-placeholders';
@import 'src/styles/placeholders/reset-placeholders';
@import 'src/styles/breakpoints';

.root {
  @extend %reset-typography;
  @extend %flex-column;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--nj-foreground--secondary);
}

.list {
  @extend %flex-row;

  flex-wrap: wrap;
}

.toggleButton {
  padding: 10px 16px;
  border-radius: 12px;
  background-color: var(--nj-panel);
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--nj-foreground--secondary);
}
