.nj-thread-combined-card {
  box-sizing: border-box;

  &--header {
    margin: 0;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &--title {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  &--container {
    box-sizing: border-box;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--nj-border);
    display: flex;
    flex-direction: column;
    gap: 8px;

    .nj-thread-combined-card--title {
      margin-bottom: 8px;
    }

    &.with-color-border {
      border-color: var(--nj-primary-button--background);
      box-shadow: 0px 4px 8px 0px var(--nj-header--control-shadow);
    }
  }

  &--actions {
    display: flex;
    gap: 8px;
  }

  &--action-button-wrapper {
    position: relative;
  }

  &--action-button {
    font-weight: 500;
    background-color: var(--nj-panel);
    color: var(--nj-foreground);
    opacity: 1;
    transition: opacity var(--nj-speed--xx) var(--nj-bezier-ease-out);

    &:disabled {
      opacity: 0.38;
    }

    &.warning {
      background-color: var(--nj-color--red);
      color: var(--nj-primary-button--foreground);
    }
  }

  &--actions-warning-tip {
    margin: 0;
    margin-top: 8px;
    color: var(--nj-color--red);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &--progress-wrapper {
    display: flex;
    gap: 8px;
    align-items: flex-start;

    .nj-task-scratchpad--progress-card-collapse-button {
      color: var(--nj-foreground--secondary);
      text-transform: uppercase;
    }

    .nj-task-scratchpad--progress-card-status-icon {
      color: var(--nj-foreground--secondary);
    }
  }

  &--progress-summary-icon {
    flex-shrink: 0;
    color: var(--nj-foreground--secondary);
  }

  &--progress-bar {
    margin-bottom: 8px;
  }

  &--progress-bar-skeleton {
    width: 100%;
    max-width: 400px;
    height: 20px;
    border-radius: 12px;
  }
}
