.root {
  padding: 6px;
  background-color: var(--nj-background);
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;

  &.prev {
    left: -1px;
  }

  &.next {
    right: -1px;
  }
}
