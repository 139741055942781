@import "src/styles/breakpoints";
@import "src/styles/placeholders/flex-placeholders";

.tab {
  @extend %flex-column;
  gap: 0;
  padding: 8px;

  @media only screen and (min-width: $nj-breakpoint-tablet) {
    flex-direction: row;
    gap: 8px;
    padding: 10px 16px;
  }

  &.highlighted {
    color: var(--nj-text-button--foreground);
  }
}

.icon {
  @extend %flex-center;

  flex-shrink: 0;
  position: relative;
}
