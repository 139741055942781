.emptyContentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > svg {
    color: var(--nj-foreground--secondary);
  }
}

.emptyContentTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  padding: 0;
  color: var(--nj-foreground);
}

.emptyContentBrowserLink {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--nj-foreground--link);
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;

  & > svg {
    color: var(--nj-foreground);
  }
}
