.nj-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background: var(--nj-background);
  background: linear-gradient(
    0deg,
    rgba(var(--nj-background--rgb), 0) 0%,
    rgba(var(--nj-background--rgb), 1) 30%,
    rgba(var(--nj-background--rgb), 1) 100%
  );
  z-index: 20;

  @media screen and (min-width: $nj-breakpoint-tablet) {
    padding: 8px 16px;
  }

  @media screen and (min-width: $nj-breakpoint-desktop) {
    padding: 8px 24px;
  }

  &-buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      width: 96px;
    }

    @media only screen and (min-width: $nj-breakpoint-tablet) {
      gap: 16px;
    }
  }

  &-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.nj-header-logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 190px;
    height: 40px;

    @media only screen and (min-width: $nj-breakpoint-tablet) {
      width: 228px;
      height: 56px;
    }
  }
}
