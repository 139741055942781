button {
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
  color: var(--nj-foreground);
  display: flex;
  align-items: center;
}

.nj-button {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 20px;
  background: var(--nj-background);
  border: 1px solid transparent;
  color: var(--nj-foreground);
  outline: none;
  text-decoration: none;

  &--primary {
    background: var(--nj-primary-button--background);
    color: var(--nj-primary-button--foreground);
    border: 1px solid var(--nj-primary-button--background);
  }

  &--secondary {
    border: 1px solid var(--nj-border);
  }
}

.nj-button--pill {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 8px;
  background-color: transparent;

  &.selected {
    border-radius: 4px;
    background-color: var(--nj-primary-button--background);
    color: var(--nj-primary-button--foreground);
  }
}

.nj-button--fab {
  position: absolute;
  bottom: 120px;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--nj-button-fab--background);
  box-shadow: 0px 2px 8px 0px var(--nj-shadow);
  backdrop-filter: blur(12px);
  cursor: pointer;
  z-index: 1000;
}
