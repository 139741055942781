.nj-citation-link {
  align-items: center;
  background: var(--nj-panel);
  border-radius: 6px;
  color: var(--nj-foreground--secondary) !important;
  display: inline-flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.2px;
  line-height: 12px;
  padding: 2px 0;
  position: relative;
  text-decoration: none;
  top: -4px;
  width: 16px;
}
