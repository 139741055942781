.nj-backround-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 320px;
  height: 324px;
  object-fit: cover;

  @media only screen and (min-width: $nj-breakpoint-tablet) {
    width: 632px;
    height: 640px;
  }
}

.nj-main {
  width: 100%;
  height: 100%;
  padding-top: 0;
  position: relative;
}

.nj-main--page-content {
  width: 100%;
  color: var(--nj-foreground);
  height: calc(100% - 114px);
  overflow-y: auto;
  padding-top: 104px;
  padding-bottom: 40px;

  @media only screen and (min-width: $nj-breakpoint-tablet) {
    height: calc(100% - 98px);
    padding: 80px 16px 40px;
  }
}

.nj-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 16px;

  @media only screen and (min-width: $nj-breakpoint-tablet) {
    margin: 0 auto;
    padding: 128px 0 0;
    width: 640px;
  }

  &--title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;

    @media only screen and (min-width: $nj-breakpoint-tablet) {
      font-size: 36px;
      line-height: 44px;
    }
  }
}

.content-scroll-mask {
  mask-image: linear-gradient(
    rgba(0, 0, 0, 0),
    #000 112px,
    #000 calc(100% - 112px),
    rgba(0, 0, 0, 0)
  );
}

.nj-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -2px 4px 0px var(--nj-shadow);
  backdrop-filter: blur(12px);
  background: var(--nj-footer--background);
  display: flex;
  width: 100%;
  padding: 16px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1000;

  p {
    color: var(--nj-foreground--secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
