.nj-modal--root {
  z-index: 10000000;
}

.nj-modal,
.nj-modal--access,
.nj-modal--notification {
  position: relative;
  background: var(--nj-panel);
  color: var(--nj-foreground);
  box-sizing: border-box;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 0px 20px 0 var(--nj-shadow);
}

.nj-modal {
  width: 768px;
  min-height: 480px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  margin: 1.2rem;
  padding: 16px 0px;

  .nj-tabbed-layout--panels {
    min-width: 480px !important;
  }
}

.nj-modal--overlay {
  background: rgba(0, 0, 0, 0.3);
}

.nj-modal--access {
  width: 640px;
  box-shadow: 0px 0px 24px 0px rgba(var(--nj-background), 0.48);
  padding: 16px 24px 24px;

  p {
    margin: 0;
  }

  &--wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &--action-wrapper {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 16px;
  }

  &--title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
  }
}

.nj-modal--large {
  margin: 0;
  box-sizing: border-box;
  max-width: 1024px;
  width: 1024px;

  @media only screen and (max-width: $nj-breakpoint-tablet) {
    max-width: 100%;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
  }
}

.nj-modal,
.nj-modal--responsive--image,
.nj-modal--responsive--video {
  position: relative;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  min-height: 80%;
  min-width: 80%;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: $nj-breakpoint-tablet) {
    max-width: 100%;
    max-height: 100%;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: 0;
  }

  @media screen and (max-width: $nj-breakpoint-desktop) and (orientation: landscape) {
    max-width: 100%;
    max-height: 100%;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
  }

  .nj-video-avatar-modal-header-name-wrapper {
    align-items: flex-start;
    width: 85%;

    @media screen and (orientation: landscape) {
      width: 100%;
    }
  }

  .nj-video-avatar-modal-header-name-wrapper {
    svg {
      min-width: 20px;
      min-height: 20px;
    }
  }

  .nj-video-avatar-modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: inherit;
    max-width: 100%;
    height: inherit;
    max-height: 100%;
    box-sizing: border-box;
    gap: 0;
    justify-content: center;
    bottom: 24px;
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;

    @media screen and (max-width: $nj-breakpoint-tablet) {
      top: 40px;
    }

    .nj-video-avatar-modal-content-select-wrapper {
      display: none;
    }

    img {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      object-fit: contain;
    }
  }
}

.nj-modal--notification {
  width: 30%;
  min-height: 200px;

  &.error,
  &.alert,
  &.warning {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--nj-status--alert);
    }
  }

  &.success {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--nj-status--success);
    }
  }

  &.status {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--nj-status--accent);
    }
  }
}

.nj-notification {
  text-align: center;
}

.nj-modal--savings-dashboard {
  padding: 40px;
}

.nj-video-avatar-modal-closeButton {
  top: 16px;
}

.nj-video-avatar-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &-actions {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-right: 40px;
  }

  &-name {
    display: flex;
    flex-direction: column;

    &-date {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: var(--nj-foreground--tertiary);
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      gap: 16px;
      max-width: calc(100% - 20px);
    }
  }
}

.nj-video-avatar-modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  &-select-wrapper {
    width: 350px;

    @media screen and (max-width: $nj-breakpoint-mobile) {
      width: 90%;
    }
  }

  &-video-player-wrapper {
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.nj-video-avatar-modal-select-option {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  &-label-wrapper {
    display: flex;
    flex-direction: column;

    .nj-video-avatar-modal-select-option-label-subtitle {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: var(--nj-foreground--secondary);
    }
  }
}
