.nj-chat-card {
  padding: 8px;
  border-radius: 12px;
  align-items: flex-start;
  align-self: stretch;
}

.nj-chat-card.plain {
  .title {
    color: var(--nj-foreground);
  }

  .collapsedContentCoverage {
    background: linear-gradient(
      180deg,
      rgba(var(--nj-background--rgb), 0) 0%,
      var(--nj-background) 100%
    );
  }
}

.nj-chat-card.blue {
  background: linear-gradient(
    180deg,
    var(--nj-progress-button-background) 0%,
    rgba(var(--nj-jean-blue-50--rgb), 0) 50%
  );

  .title {
    background: linear-gradient(
      90deg,
      var(--nj-disco-blue-600) 0%,
      var(--nj-jean-blue-500) 50%,
      var(--nj-jean-blue-650) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;

    svg {
      color: var(--nj-disco-blue-600);
    }
  }
}

.nj-chat-card.light-blue {
  background: var(--nj-external-researcher-linear-gradient);

  .title {
    color: var(--nj-tags-chips--research-title);
  }
}
.referenceModel {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--nj-foreground--secondary);
  display: flex;
  box-sizing: border-box;
  padding: 10px 16px;
  border-radius: 12px;
  background-color: var(--nj-panel);
  flex-shrink: 0;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 4px 0 !important;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.nj-citation-link {
  display: inline-flex;
  width: 16px;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--nj-panel);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: var(--nj-foreground--secondary) !important;
  text-decoration: none;
  position: relative;
  top: -4px;
}
