.nj-not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  text-align: center;
  gap: 40px;

  &--title {
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    width: 100%;

    @media only screen and (min-width: $nj-breakpoint-tablet) {
      width: calc(100% / 2);
    }
  }

  &--image {
    text-align: center;
    border-radius: 50%;
    background-color: var(--nj-ninja-loader);
  }
}
