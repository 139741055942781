@import "src/styles/placeholders/typography-placeholders";
@import "src/styles/placeholders/flex-placeholders";

.root {
  ul {
    padding: 0;
  }
}

.header,
.collapseButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header {
  padding: 8px 0;
  width: 100%;

  span {
    @extend %sub-header-1;
    color: var(--nj-foreground);
  }
}

.collapseButton {
  padding: 0 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--nj-regular-button--foreground);
  flex-grow: 0;
}

.collapseItemsBlock {
  @extend %flex-column;
  gap: 0;
}
