.nj-theme {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: var(--nj-vh);
  width: 100vw;

  a:not(.nj-button--primary) {
    color: var(--nj-foreground--link);
  }
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
