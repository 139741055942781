@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

body {
  font-family: var(--nj-font--body);
  font-size: 1em; // 14px
  font-weight: 400;
  line-height: 1.4; // 20px
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0 0 16px;
}

h1 {
  font-family: var(--nj-font--heading);
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
}

h2 {
  font-family: var(--nj-font--heading);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

h3 {
  font-family: var(--nj-font--heading);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

h4 {
  font-family: var(--nj-font--heading);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

h5 {
  font-family: var(--nj-font--heading);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

h6 {
  font-family: var(--nj-font--heading);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.title {
  font-family: var(--nj-font--heading);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.subtitle {
  font-family: var(--nj-font--heading);
  font-size: 14px;
  font-weight: 400; // 500
  line-height: 20px;
}

.caption {
  font-family: var(--nj-font--body);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

small {
  font-size: 10px;
  line-height: 12px;
}

button,
select,
input {
  font-family: var(--nj-font--body);
}
