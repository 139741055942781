.nj-share {
  &--modal {
    padding: 0;

    @media screen and (min-width: $nj-breakpoint-tablet) {
      min-width: 480px;
    }
  }

  &--title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
    padding: 16px 60px 16px 24px;

    @media screen and (min-width: $nj-breakpoint-tablet) {
      max-width: 380px;
      padding: 16px 24px;
    }
  }

  &--copy-block {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 0 24px 16px;
    flex-wrap: wrap;

    @media screen and (min-width: $nj-breakpoint-tablet) {
      flex-wrap: nowrap;
      gap: 32px;
      justify-content: space-between;
    }
  }

  &--copy-button {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    background-color: var(--nj-background);
    white-space: nowrap;
  }

  &--buttons-block {
    display: flex;
    gap: 32px;
    justify-content: center;
    padding: 16px 24px 24px;
  }

  &--copy-field-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    color: var(--nj-foreground--link);
    text-decoration: none;

    @media screen and (min-width: $nj-breakpoint-tablet) {
      text-overflow: initial;
    }
  }
}

.nj-copy-snackbar {
  min-width: unset;
  width: auto;
  padding: 8px 10px;
  border-radius: unset;
  background: var(--nj-snackbar);
  backdrop-filter: blur(16px);
  color: var(--nj-foreground);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &-content {
    width: 100%;
    min-width: unset;
    display: inline-flex;
    align-items: center;
    gap: 16px;
  }
}

.nj-multi-menu {
  border-radius: 8px;
  border: 1px solid var(--nj-border);
  background: var(--nj-nav--background);
  box-shadow: 0px 4px 4px 0px rgba(var(--nj-neutral-800), 0.25);
  backdrop-filter: blur(12px);
  color: var(--nj-foreground);

  .szh-menu__item {
    font-size: 14px;
    padding: 10px 32px 10px 16px !important;

    & > a {
      text-decoration: none;
      color: var(--nj-foreground);
    }
  }

  .szh-menu__item,
  .szh-menu__item > a {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .szh-menu__divider {
    background-color: var(--nj-border);
  }

  .szh-menu__item.szh-menu__item--hover,
  .szh-menu__item.selected {
    background: var(--nj-button--background-selected);
  }

  .szh-menu__item.szh-menu__item--hover::after,
  .szh-menu__item.selected::after {
    position: absolute;
    right: 16px;
    content: '•';
  }

  &--menu-item {
    display: flex;
    gap: 16px;
  }
}

.nj-share-menu-copy-block {
  padding: 24px 24px 16px;

  .nj-share-menu-copy-field {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 16px;
    border-radius: 20px;
    background-color: var(--nj-chatform--background);

    &-link {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-decoration: none;
      color: var(--nj-foreground);

      @media screen and (min-width: $nj-breakpoint-tablet) {
        max-width: max-content;
      }
    }

    & button {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--nj-status--accent);
    }
  }
}

.nj-share-menu-buttons-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding: 16px 24px 20px;
}
