@import 'src/styles/breakpoints';

.nj-view-in-browser-button {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  min-width: 80px;
  height: 48px;
  padding: 14px 16px;
  align-items: flex-start;
  text-decoration: none;
  color: var(--nj-foreground) !important;
  border: 1px solid var(--nj-foreground);
  border-radius: 24px;

  @media only screen and (max-width: $nj-breakpoint-lower-than-desktop) {
    width: 40px;
    height: 40px;
    padding: 8px;
    border: none;
    min-width: unset;
    background-color: var(--nj-panel);
  }
}
