%header-1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

%header-2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

%header-3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

%sub-header-1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

%sub-header-1-alt {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

%sub-header-2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

%sub-header-2-alt {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

%sub-header-4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

%body-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

%system-body-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

%system-body-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

%caption-1 {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

%caption-2 {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
}

%button {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

%paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
