.nj-message--research-summary--rich-media {
  .nj-bubble.system {
    background-color: var(--nj-card-rich-content--background);
  }
}

.nj-thread-message--content > p:first-child,
.nj-thread-research-card--collapsed-content-block > p:first-child {
  margin-top: 0 !important;
}

.nj-research-card {
  h1 {
    padding: 8px 0;
    margin: 0 0 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
}

.nj-research-card--section {
  margin-bottom: 24px;
  border-bottom: 1px solid var(--nj-border);
  padding: 24px 0;
}

.nj-research-card.nj-research-card--rich-media {
  h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .nj-message--research-summary--answer {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: var(--nj-foreground);
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      & > span:last-of-type {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: var(--nj-foreground);
        position: relative;
        top: 2px;
      }
    }

    span {
      font-weight: 500;
      color: var(--nj-foreground--secondary);
    }
  }
}

.nj-research-card--actions {
  float: right;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  button {
    width: 28px;
    height: 28px;
    overflow: hidden;
    background-color: var(--nj-button-research-card-background);
    color: var(--nj-foreground);
  }
}

.nj-research-card--row + .nj-research-card--row {
  margin-top: 16px;
}

.nj-research-card--row.heading + .nj-research-card--row {
  margin-top: 0px;
}

.nj-accordion + .nj-research-card--row {
  margin-top: 18px;
}

.nj-media-carousel {
  &--header {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  &--media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 360px;
    text-align: center;
    object-fit: contain;
    cursor: pointer;
  }

  &--media.video {
    width: auto;
    height: 360px;
  }

  &--swiper {
    padding: 16px 0 0;

    .swiper-scrollbar {
      display: none;
    }
  }

  &--container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    gap: 0px;

    .swiper-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--nj-button-fab--background);
      color: var(--nj-button-fab--foreground);
      transform: translateY(-50%);
      box-shadow: 0px 1px 4px 0px var(--nj-deep-shadow);
      cursor: pointer;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 20px;
    }
  }

  &--slide {
    max-width: 212px;
    padding: 3px;
    box-sizing: border-box;

    div {
      position: relative;
      height: 112px;
      border-radius: 8px;
      overflow: hidden;

      .nj-button--square-overlay {
        position: absolute;
        padding: 0;
        margin: 0;
        right: 8px;
        top: 8px;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        z-index: 3;
        background: var(--nj-media-overlay--background);
        color: var(--nj-media-overlay--foreground);

        .container {
          height: 100%;
          width: 100%;
        }
      }

      img {
        width: 100%;
        aspect-ratio: 1/1;
        height: auto;
        object-fit: cover;
      }
    }

    div.selected {
      outline: 2px solid var(--nj-status--accent);
      box-sizing: border-box;
      border-radius: 8px;

      .nj-button--square-overlay {
        background: var(--nj-status--accent);
      }

      img {
        background: var(--nj-media-overlay--foreground);
        opacity: 0.5;
      }
    }
  }
}
