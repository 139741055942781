.nj-thread-message {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin: 0 auto;
  margin-top: 24px;
  width: 100%;
  max-width: 756px;
  flex-direction: column;

  @media only screen and (min-width: $nj-breakpoint-tablet) {
    gap: 16px;
    flex-direction: row;
    max-width: 946px;
    padding-right: 48px;
  }

  &:first-of-type {
    .nj-message-footer {
      opacity: 1;
    }
  }

  &:hover {
    .nj-thread-message--date,
    .nj-message-footer {
      opacity: 1;
    }
  }

  &--author-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &--container {
    max-width: 100%;
    box-sizing: border-box;
    flex-grow: 1;

    @media only screen and (min-width: $nj-breakpoint-tablet) {
      padding-top: 8px;
      max-width: calc(100% - 52px);
    }
  }

  &--header {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 4px;
    width: 100%;

    &.researcher {
      margin-bottom: 6px;
      padding: 0 8px;
    }
  }

  &--header-wrapper {
    display: flex;
    gap: 8px;
  }

  &--date {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--nj-foreground--secondary);
    transition: opacity var(--nj-speed--xx) var(--nj-bezier-ease-out);

    @media only screen and (min-width: $nj-breakpoint-desktop) {
      opacity: 0;
    }
  }

  &--content {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
    word-wrap: break-word;

    a {
      color: var(--nj-foreground--secondary);
    }
  }

  &--anchor {
    position: relative;
    top: -80px;
  }
}
