.root {
  min-width: 80px;
  border-radius: 12px 12px 0px 0px;

  &:hover {
    background-color: var(--nj-nav--pressed-background);
    border-radius: 12px 12px 0px 0px;
  }

  &.active {
    border-radius: 12px 12px 0px 0px;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0px;
      height: 2px;
      background-color: var(--nj-foreground--link);
      width: 100%;
    }
  }
}
