.fadeEnter {
  opacity: 0;
}

.fadeEnterActive {
  opacity: 1;
  transition: opacity var(--nj-speed--xx) var(--nj-bezier-ease-in-out);
}

.fadeExit {
  opacity: 1;
}

.fadeExitActive {
  opacity: 0;
  transition: opacity var(--nj-speed--xx) var(--nj-bezier-ease-in-out);
}
