@import "src/styles/placeholders/flex-placeholders";

.root {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 100%;

  :global(.swiper-slide) {
    width: auto;
  }
}
