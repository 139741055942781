.nj-thread-view {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: all var(--nj-speed--xx) var(--nj-bezier-ease-in-out);
  max-height: var(--nj-vh);
  overflow-y: auto;
  // TODO(olha): check and update styles
  display: flex;
  flex-direction: column-reverse;

  &.avatar-mode {
    align-items: flex-end;
  }

  @media only screen and (min-width: $nj-breakpoint-tablet) {
    &.narrow-left {
      padding-left: 360px;
    }

    &.narrow-right {
      padding-right: 324px;
    }
  }

  .nj-pillars {
    position: relative;
    left: -48px;
  }

  //.nj-meta-human {
  //  left: -24px;
  //}
}

.nj-thread-view.avatar-mode {
  display: table;
  width: 100%;

  .nj-thread-view--avatar,
  .nj-thread-view--chat {
    display: table-cell;
    overflow: hidden;
    position: relative;
    transform: translateX(0);
    transition: all var(--nj-speed--xx) var(--nj-bezier-ease-in-out);
  }

  .nj-thread-view--avatar {
    vertical-align: middle;
  }

  .nj-thread-view--chat {
    vertical-align: bottom;
    box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.6);
  }

  .nj-meta-human,
  .nj-thread-message-list {
    position: relative;
  }

  .nj-thread-view--avatar {
    width: 100%;
    height: 100%;
    background: var(--nj-avatar--background);
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nj-thread-view--chat {
    max-width: 0;

    @media only screen and (max-width: $nj-breakpoint-tablet) {
      max-width: 0;
    }
  }

  &.show-transcript {
    .nj-thread-view--chat {
      max-width: calc(592px + 64px);

      .nj-thread-message-list {
        min-width: calc(592px + 64px);
      }

      @media only screen and (max-width: $nj-breakpoint-tablet) {
        max-width: calc(480px + 64px);

        .nj-thread-message-list {
          min-width: calc(480px + 64px);
        }
      }
    }
  }
}
