.nj-accordion {
  &--panel {
    border-bottom: 1px solid var(--nj-border);
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    cursor: pointer;
  }

  &--label {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--nj-foreground--secondary);

    & > svg {
      flex-shrink: 0;
    }
  }

  &--label-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &--title,
  &--subtitle {
    margin: 0;
  }

  &--title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--nj-foreground);
  }

  &--subtitle {
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    &.hide {
      display: none;
    }
  }
}

.nj-accordion.nj-avatar-recording--accordion {
  .nj-accordion--header {
    padding: 32px 16px;
  }

  .nj-accordion--content {
    padding: 0px 16px;
  }
}

.nj-accordion.nj-accordion--research-summary {
  margin-top: 24px;
  border-top: 1px solid var(--nj-border);

  h2,
  .nj-accordion--label,
  .nj-accordion--title {
    color: var(--nj-nav--selected-foreground);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .nj-accordion--title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--nj-foreground);
  }

  .nj-accordion--subtitle {
    display: none;
  }

  .nj-accordion--header {
    padding: 0;
  }

  .nj-accordion--panel {
    padding: 16px 0;
  }

  .nj-accordion--research-summary--content-wrapper {
    padding-top: 16px;
  }
}

// animatable accordion
.nj-accordion {
  .nj-accordion--content {
    display: block;
    padding: 0;
    height: auto;
    max-height: 0;
    opacity: 0;
    transition:
      opacity var(--nj-speed--xx) var(--nj-bezier-ease-in),
      max-height var(--nj-speed--xx) var(--nj-bezier-ease-in);
    overflow: hidden;
  }

  .nj-accordion--panel.open {
    .nj-accordion--content {
      opacity: 1;
      max-height: auto;
    }
  }
}
