.nj-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &--player-wrapper {
    position: relative;

    &:hover {
      .hide {
        opacity: 1;
      }
    }
  }

  &--player-header {
    padding: 8px 16px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    @media screen and (min-width: $nj-breakpoint-tablet) {
      padding: 8px 24px;
    }

    &.hide {
      opacity: 0;
    }
  }

  &--player-header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--nj-neutral-000);
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--share-button {
    color: var(--nj-neutral-000);
  }
}
