.nj-thread-schedule-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--nj-border);
  padding: 16px;
  box-sizing: border-box;

  h3 {
    padding: 16px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 4px;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }

  &--summary {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &--link {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--nj-foreground--link);
    text-decoration: none;
    white-space: nowrap;
    flex-shrink: 0;
  }

  &--date-panel {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
  }

  &--date-button {
    padding: 10px 16px;
    border-radius: 20px;
    color: var(--nj-foreground);
    width: min-content;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    // border: 1px solid var(--nj-border);
    display: flex;
    align-items: center;
    gap: 8px;

    &.selected {
      background-color: var(--nj-tab--background-selected);
      color: var(--nj-card--link);
      // border: 1px solid var(--nj-tab--background-selected);

      .nj-thread-schedule-card--date-button-counter {
        background-color: var(--nj-foreground--link);
      }
    }
  }

  &--date-button-counter {
    flex-shrink: 0;
    box-sizing: border-box;
    min-width: 16px;
    padding: 2px 3px;
    border-radius: 10px;
    background-color: var(--nj-foreground--secondary);
    color: var(--nj-background);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  }

  &--current-date {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    padding: 10px 16px;
  }

  a {
    color: var(--nj-foreground) !important;
    text-decoration: none;
  }

  &--event {
    display: grid;
    grid-template-columns: 190px 1fr;
    grid-gap: 16px;
    align-items: center;
    padding: 10px 16px;

    p {
      margin: 0;
    }

    &.selected {
      border-radius: 20px;
      color: var(--nj-foreground);
      background-color: var(--nj-tab--background-selected);

      .nj-thread-schedule-card--event-time-range,
      .nj-thread-schedule-card--event-title {
        font-weight: 600;
      }
    }
  }

  &--event-time-range {
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    align-items: center;
  }

  &--event-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &--expand-button {
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 0 auto;
    color: var(--nj-foreground--link);
  }
}
