@import "src/styles/placeholders/typography-placeholders";
@import "src/styles/placeholders/flex-placeholders";
@import "src/styles/breakpoints";

.root {
  @extend %flex-center;
  flex-direction: column;

  background: var(--nj-background);
  border-radius: 24px;
  border-radius: 12px;
  height: 90%;
  width: 70%;

  @media screen and (max-width: $nj-breakpoint-lower-than-desktop) {
    width: 95%;
    height: 90%;
  }
}

.errorContainer {
  @extend %flex-column-center;
  cursor: auto;
  text-align: center;

  span {
    @extend %header-2;
    padding: 8px;
    color: var(--nj-foreground--secondary);
  }

  svg {
    margin-bottom: 8px;
    fill: var(--nj-foreground--secondary);
  }
}
