.nj-ninja-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  text-align: center;
  gap: 36px;

  &--images {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 184px;
    height: 184px;
  }

  &--image {
    position: relative;
    text-align: center;
    border-radius: 50%;
    background-color: var(--nj-ninja-loader);
    z-index: 2;
  }

  &--spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-color: var(--nj-status--in-progress) rgba(0, 0, 0, 0)
      rgba(0, 0, 0, 0);
    border-style: solid;
    border-top-color: var(--nj-status--in-progress);
    border-width: 4px;
    box-sizing: border-box;
    display: block;
    border-radius: 50%;
    animation: infinite-spinner var(--nj-speed--5x) linear infinite;
    z-index: 1;
  }

  &--content {
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    width: 100%;
    color: var(--nj-foreground--secondary);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;

    @media only screen and (min-width: $nj-breakpoint-tablet) {
      width: calc(100% / 2);
    }
  }
}
