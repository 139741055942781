@import "src/styles/placeholders/flex-placeholders";
@import "src/styles/placeholders/typography-placeholders";
@import "src/styles/mixins/multiline-ellipsis";

.root {
  @extend %flex-row;

  gap: 16px;
  padding: 8px 0 8px 8px;
  border-bottom: 1px dashed var(--nj-border);
  width: 100%;
  justify-content: flex-start;
  text-align: left;
}

.content {
  @extend %paragraph;

  flex-grow: 1;
}

.icon {
  padding: 6px;
  flex-shrink: 0;
}
