.nj-thread-code-card,
.nj-task-scratchpad--code-card {
  margin-top: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  .nj-markdown-block-wrapper {
    display: flex;
    flex-direction: column;
    background: var(--nj-nav--pressed-background);
    padding: 0 8px 8px;
    border-radius: 16px;

    .nj-markdown-copy-block-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 8px 8px;
      color: var(--nj-foreground--secondary);
      align-items: center;

      h6 {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
        padding: 0;
        color: var(--nj-foreground);
      }

      button {
        color: var(--nj-outlined-button--foreground);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .divider {
      width: calc(100% + 16px);
      margin: 8px 0 8px -8px;
    }
  }
}
