@import "src/styles/placeholders/flex-placeholders";
@import "src/styles/placeholders/typography-placeholders";

.root {
  @extend %flex-center;
  @extend %button;

  position: relative;
  color: var(--nj-foreground);
  border: none;
  gap: 8px;
  outline: none;

  &:disabled {
    opacity: var(--nj-opacity-primary);
    cursor: not-allowed;
  }
}

.round {
  padding: 10px;
  border-radius: 50%;
}

.regular {
  padding: 10px 16px;
  border-radius: 20px;
  white-space: nowrap;
}

.primary {
  background-color: var(--nj-primary-button--background);
  color: var(--nj-background);
}

.secondary {
  padding: 9px 16px;
  background: transparent;
  border: 1px solid var(--nj-foreground);
}

.transparent {
  background: transparent;
  padding: 10px;
}

.transparent.regular {
  border-radius: 0;
}
